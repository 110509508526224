.container {
    width: 100%;
}

.form_container {
    display: flex;
    align-items: center;
    justify-content: center;
}
.form{
    max-width: 450px;
    border: 1px solid #ececec;
    margin-top: 20px;
}
.form:global(.screen-x-small) {
    width: 100%;
    border: 0;
}

.cover_img_container {
    margin-bottom: 5px;
}

.cover_img_container img {
    width: 100%;
    height: auto;
}

.form_area {
    padding: 1rem;
}

.caption {
    font-size: 18px;
}

.form_group {
    margin-bottom: 20px;
    margin-top: 10px;
}

.form_label {
    font-size: 14px;
}

.form_help_text {
    margin-top: 5px;
    color: rgba(0, 0, 0, 0.5);
}

.form_actions {
    padding-top: 10px;
    padding-bottom: 20px;
}
.result {
    padding-top: 50px;
    padding-bottom: 50px;
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.result img{
    width: 128px;
    height: auto;
    margin-left: auto;
    margin-right: auto;
}
.result p{
    font-size: 20px;
    line-height: 30px;
    text-align: center;
}
.result b{
    color: #03a9f4;
}