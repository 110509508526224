.cover_img {
    width: 100%;
    height: auto;
}

.add_img_container {
    min-height: 150px;
    background-color: #ececec;
    display: flex;
    justify-content: center;
    align-items: center;
}

.remove_img_container {
    
    text-align: right;
    padding-right: 10px;
}
.remove_img_container a{
    color: red;
}